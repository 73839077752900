import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EntityDataServiceBase } from "@shared-base/entity-data-service.base";
import { UrlQueryDef } from "@shared-base/url-query-definition.base";
import { TranslateService } from "@shared-services/translate.service";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class SystemServiceDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    protected translateService: TranslateService)
  {
    super(http, 'systemServices');
  }

  public isMaintenanceDisabled(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.params = {
      language: this.translateService.languageCode
    };

    const subject = this.http.get<boolean>(this.createUrl("isEnabled"), queryDef.getRequestConfig());

    return subject;
  }
}
