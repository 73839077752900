import { HttpHeaders, HttpParams } from "@angular/common/http";
import { HttpHeadersConst } from "@core-constants/http-headers.const";

export class UrlQueryDef
{
  // ********************************************************************
  //#region Properties / Variables
  // ********************************************************************

  public includes: string = "";
  public domainSpecificContextId?: number;
  public params: any;
  public headers: { [header: string]: string } = {};

  // #endregion

  // ********************************************************************
  //#region Methods
  // ********************************************************************

  /** Method to add HttpParams into our request. Accepts a <name> <value> pair */
  public addParam(name: string, value: any): void
  {
    if (this.params == null) { this.params = {}; }

    this.params[name] = value;
  }

  /** Method to add headers into our request. Accepts a <header> <value> pair */
  public addHeader(name: string, value: string): void
  {
    if (name && value)
    {
      this.headers[name] = value;
    }
  }

  public getRequestConfig(): any
  {
    const requestConfig = {
      params: this._getParamsMap(),
      headers: this._getHeaders()
    };

    return requestConfig;
  }

  public getRequestConfigWithAuthorizationToken(authorizationToken, languageCode = null): any
  {
    const requestConfig = this.getRequestConfig();

    if (authorizationToken != null)
    {
      this.addHeader(HttpHeadersConst.Authorization, authorizationToken);
    }

    if (languageCode != null && !requestConfig.headers.has(HttpHeadersConst.AcceptLanguage))
    {
      this.addHeader(HttpHeadersConst.AcceptLanguage, languageCode);
    }

    requestConfig.headers = this._getHeaders();
    return requestConfig;
  }

  public getRequestConfigForRedirect(authorizationToken): any
  {
    const requestConfig = this.getRequestConfig();

    if (authorizationToken != null)
    {
      this.addHeader(HttpHeadersConst.Authorization, authorizationToken);
      this.addHeader(HttpHeadersConst.AccessControlAllowOrigin, '*');
    }

    requestConfig.headers = this._getHeaders();
    requestConfig['responseType'] = 'text';

    return requestConfig;
  }

  // #endregion

  // ********************************************************************
  //#region Support Methods
  // ********************************************************************

  private _getParamsMap(): HttpParams
  {
    let paramsMap = new HttpParams();

    if (this.params != null)
    {
      // Add properties from params to params map
      for (const paramName in this.params)
      {
        paramsMap = paramsMap.append(paramName, this.params[paramName]);
      }
    }

    if (this.includes != "")
    {
      paramsMap = paramsMap.append("includes", this.includes);
    }

    if (this.domainSpecificContextId)
    {
      paramsMap = paramsMap.append("domainSpecificContextIds", this.domainSpecificContextId.toString());
    }

    return paramsMap;
  }

  private _getHeaders(): HttpHeaders
  {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Vary': 'Origin, Cache-Control'
      });

    for (const headerName in this.headers)
    {
      headers = headers.append(headerName, this.headers[headerName]);
    }

    return headers;
  }

  //#endregion
}
