import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsManager } from '@core-managers/settings.manager';
import { TokenManager } from '@core-managers/token.manager';
import { IRefreshTokenMSCVDto, ITokenMSVCResponse } from '@core-models/msvc-tokens.model';
import { UserAccessRequest } from '@core-models/user-access.model';
import { Environment } from '@environments';
import { EntityDataServiceBase } from '@shared-base/entity-data-service.base';
import { UrlQueryDef } from '@shared-base/url-query-definition.base';
import { TranslateService } from '@shared-services/translate.service';
import { HttpHeadersConst } from '@core-constants/http-headers.const';
import { Observable } from "rxjs";
import { map, share } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserAccessDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    public settingsManager: SettingsManager,
    public translateService: TranslateService,
    private tokenManager: TokenManager)
  {
    super(http, "user-access");
  }

  public login(dto: UserAccessRequest.ILogin, captchaResponse: string): any
  {
    const config: UrlQueryDef = this.getStandardConfig(this.translateService.languageCode);
      
    config.addHeader(HttpHeadersConst.GoogleCaptcha, captchaResponse);

    return this.http.post(this.createMicroserviceURL("login"), dto, config)
      .pipe(share(), map((response: any) =>
      {
        return response;
      }));
  }

  public loginEsu(loginData: UserAccessRequest.ILoginEsu): Observable<any>
  {
    const config = this.getStandardConfig(this.translateService.languageCode);

    return this.http.post(this.createMicroserviceURL("login-esu"), loginData, config)
      .pipe(share(), map((response: any) =>
      {
        return response;
      }));
  }

  public logout(): Observable<any>
  {
    const queryDef: UrlQueryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();

    queryDef.params = {
      language: this.translateService.languageCode
    };

    return this.http.delete(this.createMicroserviceURL("logout"), queryDef.getRequestConfigWithAuthorizationToken(token))
      .pipe(share(), map((response: any) =>
      {
        return response;
      }));
  }

  public singup(dto: UserAccessRequest.ISingup, captchaResponse: string): Observable<any>
  {
    const config: UrlQueryDef = this.getStandardConfig(this.translateService.languageCode);

    config.addHeader(HttpHeadersConst.GoogleCaptcha, captchaResponse);

    const subject = this.http.post(this.createMicroserviceURL("singup"), dto, config).pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public refreshToken(dto: UserAccessRequest.IRefreshToken): Observable<any>
  {
    const subject = this.http.post(this.createMicroserviceURL("refresh"), dto).pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public validateSession(): Observable<any>
  {
    const dto: UserAccessRequest.IValidateSession =
    {
      language: this.translateService.languageCode,
      token: this.tokenManager.getToken()
    };

    const subject = this.http.post(this.createMicroserviceURL("session"), dto).pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public redirectToAkkyOnLogin(postBackUrl: string = ""): void
  {
    this.redirectToAkky(postBackUrl, false);
  }

  public redirectToAkkyOnLogout(postBackUrl: string = ""): void
  {
    this.redirectToAkky(postBackUrl, true);
  }

  private redirectToAkky(postBackUrl: string = "", isLogout: boolean = false): void
  {
    const f = document.createElement('form');
    f.action = `${Environment.PortalAkkyURL}session`;
    f.method = 'POST';

    if (isLogout === false)
    {
      const auth = document.createElement('input');
      auth.type = 'hidden';
      auth.name = 'Authorization';
      auth.value = 'Bearer ' + this.tokenManager.getToken();

      f.appendChild(auth);

      if (postBackUrl != "")
      {
        const redirect = document.createElement('input');
        redirect.type = 'hidden';
        redirect.name = 'RedirectUrl';
        redirect.value = postBackUrl;

        f.appendChild(redirect);
      }
    }
    else
    {
      const logout = document.createElement('input');
      logout.type = 'hidden';
      logout.name = 'IsLogout';
      logout.value = "true";

      f.appendChild(logout);
    }

    document.body.appendChild(f);
    f.submit();
  }

  public getMSVCToken(): Observable<any>
  {
    const subject = this.http.get(this.createMicroserviceURL("authorization/token")).pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public getMSVCInitialToken(): Observable<any>
  {
    return this.http.get(this.createMicroserviceURL("authorization/token")).pipe(
      map((response: ITokenMSVCResponse) =>
      {
        this.tokenManager.saveMSVCToken(response);
        return response;
      })
    );
  }

  public getMSVCRefreshToken(dto: IRefreshTokenMSCVDto): Observable<any>
  {
    const subject = this.http.post(this.createMicroserviceURL("authorization/refresh-token"), dto).pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }
}

