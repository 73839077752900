import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiServiceBase } from './api-service.base';
import { UrlQueryDef } from './url-query-definition.base';
import { HttpHeadersConst } from "@core-constants/http-headers.const";

export class EntityDataServiceBase extends ApiServiceBase
{
  constructor(protected http: HttpClient,
    public apiUrl: string)
  {
    super(http);
    this.apiURL = apiUrl;
  }

  // *********************************************************
  // #region Support Methods
  // *********************************************************

  public _getQueryDef(queryDef: UrlQueryDef): UrlQueryDef
  {
    if (queryDef == null) 
    {
      queryDef = new UrlQueryDef();
    }

    return queryDef;
  }

  protected getStandardConfig(lang: string, config: UrlQueryDef | any = undefined): UrlQueryDef
  {
    if (!config)
    {
      config = new UrlQueryDef();
      config.addHeader(HttpHeadersConst.ContentType, 'application/json');
      config.addHeader(HttpHeadersConst.AcceptLanguage, lang);
    }

    return config;
  }

  // #endregion

}
