  export const ErrorMessageConst = {
    ExpiredSession: 'Sesión expirada',
    ErrorDeletingItem: "No se pudo eliminar el servicio del carrito",
    OperationFailed: "No se pudo completar la operación",

    BadRqequest: 'No se pudo procesar la información, favor de revisar con el Administrador.',

    InvalidDomainCharacters: 'Los caracteres válidos son números, letras del alfabeto inglés y el guión (-). No deben llevar guión al comienzo ni al final del nombre, ni dos guiones seguidos. La longitud del nombre no debe exceder de 63 caracteres.',
    InvalidTLD: 'TLD no válido, con Akky puedes registrar los siguientes TLDs: .MX y sus distintas clasificaciones, .COM, .NET, .CC, .TV, .LAT, .AUTOS, .LOL, .MOM y .XYZ.',
    DomainMaintenance: 'Registro no disponible temporalmente.',
    PartialError: 'Algunos dominios no se agregaron.',
    LinkStudentDuplicated: 'El link de tu página ya ha sido registrado.',
    EmailStudentDuplicated: 'Tu Email institucional ya ha sido registrado.',
    EmailProjectStudentDuplicated: 'El Email de tu proyecto ya ha sido registrado.',

    CouponStudentDuplicated: 'La Matrícula ya ha sido registrada.',

    ChatUnavailable: "Chat temporalmente no disponible",

    CaptchaNeededToContinue: "Por favor, complete el CAPTCHA para continuar."

};
