<div id="body-bg" class="p-0 m-0 d-flex">
  <div class="container p-0 px-sm-3 px-md-3 px-lg-5 d-flex justify-content-center">
    <div class="row justify-content-center p-0 m-0">
      <div class="col-12 p-0 m-0 d-flex align-items-center">
        <div class="w-100 text-center">

          <div id="akkyLogo" class="py-3 mt-3">
            <img id="akkyLogo_img" loading="lazy" [src]="'/logo-akky-black.svg' | blobstorage"
                 data-src="{{ '/logo-akky-black.svg' | blobstorage}}" title="Akky" width="100%" alt="Akky logo">
          </div>

          <!-- language -->
          <div id="changeIdiom" class="pb-3">
            <span class="highlight-lang">
              <span class="text-uppercase clickable" (click)="ngOnLanguageChange('es')">ES</span>
              |
              <span class="text-uppercase clickable" (click)="ngOnLanguageChange('en')">EN</span>
            </span>
          </div>

          <div class="section-improving justify-content-center">
            <p class="m-0">{{'Estamos' | translate}} <span class="highlight">{{'mejorando' | translate}}</span> <br />{{'nuestros servicios' | translate}}.</p>
          </div>

          <div class="p-0 m-0 d-flex align-items-center justify-content-center">
            <img id="mt-img" loading="lazy" alt="mantenimiento" class="ms-5 ms-md-0" [src]="'/maintenance/maintenance-akky.svg' | blobstorage" data-src="{{ '/maintenance/maintenance-akky.svg' | blobstorage }}">
          </div>

          <div class="my-3 schedule-message" *ngIf="data">
            {{'Estaremos de vuelta' | translate}}
            <span *ngIf="langCode === 'es'">
              el
              <span class="fw-bold">
                <span id="day">{{ data.dayName | translate}}</span><span id="date"> {{ data.dayNumber }} de {{ data.month | translate }} </span>
              </span>
            </span>

            <span *ngIf="langCode === 'en'">
              on
              <span class="fw-bold">
                <span id="day">{{ data.dayName | translate}},</span><span id="date"> {{ data.month | translate}} {{ data.dayNumber }} </span>
              </span>
            </span>
            <span class="fw-bold">
              {{'a las'| translate}} <span id="time">{{ data.hour }}</span>
            </span>
            <br />
            {{'¡Agradecemos tu comprensión!'| translate}}
          </div>

          <div class="my-5">
            <span class="msb d-block follow-hare-message">{{'¡Sigue a la liebre!'| translate}}</span>
            <div id="d-flex">
              <a href="https://www.facebook.com/akkyMx" target="_blank">
                <img [src]="'/maintenance/rrss/rs-fb.svg' | blobstorage" data-src="{{ '/maintenance/rrss/rs-fb.svg' | blobstorage}}"
                     loading="lazy" alt="facebook" class="mx-auto" height="40">
              </a>&nbsp;&nbsp;
              <a href="https://www.instagram.com/akkymx/" target="_blank">
                <img [src]="'/maintenance/rrss/rs-insta.svg' | blobstorage" data-src="{{ '/maintenance/rrss/rs-insta.svg' | blobstorage }}"
                     loading="lazy" alt="instagram" class="mx-auto" height="40">
              </a>&nbsp;&nbsp;
              <a href="https://twitter.com/akkyMx" target="_blank">
                <img [src]="'/maintenance/rrss/rs-x.svg' | blobstorage" data-src="{{'/maintenance/rrss/rs-x.svg' | blobstorage }}"
                     loading="lazy" alt="twitter" class="mx-auto" height="40">
              </a>&nbsp;&nbsp;
              <a href="https://mx.linkedin.com/company/akky" target="_blank">
                <img [src]="'/maintenance/rrss/rs-linkedIn.svg' | blobstorage" data-src="{{ '/maintenance/rrss/rs-linkedIn.svg' | blobstorage }}"
                     loading="lazy" alt="linkedin" class="mx-auto" height="40">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
