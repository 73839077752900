import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { UrlQueryDef } from '@shared-base/url-query-definition.base';
import { Observable, of, share } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CanActivateView implements CanActivateView
{
  constructor(@Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private http: HttpClient) { }

  public canActivate(): Observable<boolean>
  {
    if (isPlatformBrowser(this.platformId))
    {
      const queryDef = new UrlQueryDef();
      this.http.get("api/systemServices/isEnabled", queryDef.getRequestConfig()).pipe(share()).subscribe({
        next: (isEnabled: any) =>
        {
          if (isEnabled === true)
          {
            if (this.router.url.includes('/maintenance'))
            {
              this.router.navigate(['/']);
              return of(!isEnabled);
            }

            return of(isEnabled);
          }
          else
          {
            this.router.navigate(['/maintenance']);
            return of(isEnabled);
          }

        }
      });
      return of(true);
    }
  }
}
